<template>
	<div
		v-if="active"
		class="modal-login"
	>
		<form 
			name="form-modal-login"
			@submit="() => false"
		>
			<Card background="block">
				<div class="form-modal-login">
					<div 
						class="time"
						@click="() => this.active = false"
					>
						X
					</div>
					<div class="form-modal-header">
						<img src="@ebradi/assets/img/logo.svg">
						<h2>Bem vindo de volta!</h2>
						<h5>Faça login na sua conta para continuar</h5>
					</div>
					<fieldset class="form-modal-inputs">
						<b-field
							label="CPF"
						>
							<b-input v-model="form.login" />
						</b-field>
						<b-field
							label="Senha"
						>
							<b-input 
								type="password"
								v-model="form.password"
								password-reveal
							/>
						</b-field>
						<p>Esqueceu sua senha? Clique <a href="#">aqui</a></p>
						<div class="btns">
							<b-button
								type="is-primary"
								rounded
							>
								Entrar
							</b-button>
						</div>
					</fieldset>
				</div>
			</Card>
		</form>
	</div>
</template>

<script>
import Card from '@ebradi/componets/Card'

export default {
	name: 'ModalLoginComponetEBRADI',
	components: {
		Card
	},
	props: {
		value: {
			type: Boolean,
			required: false,
			default: null
		}
	},
	data() {
		return {
			active: false,
			form: {
				login: null,
				password: null
			}
		}
	},
	mounted() {
		this.active = this.value
	},
	watch: {
		value(value) {
			this.active = value
		},
		active(value, oldValue) {
			if (value !== oldValue) {
				this.$emit('input', value)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.modal-login {
	display: flex;
	position: fixed;
	justify-content: center;
	align-items: flex-start;
	background: rgba(0, 0, 0, 0.8);
	width: 100%;
	height: 100%;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	opacity: 0;
	animation: opacity forwards 0.4s;
	z-index: 999;

	&  > form {
		max-width: 600px;
		width: 100%;
	}
}

.form-modal-login {
	margin-top: 42px;
	padding-bottom: 42px;
	position: relative;

	& > .time {
		position: absolute;
		top: 20px;
		right: 40px;
		font-weight: 700;
		font-size: 16;
		cursor: pointer;
		transition: 0.4s;

		&:hover {
			color: var(--primary);
		}
	}

	& > .form-modal-header {
		text-align: center;
		margin-bottom: 42px;

		img {
			margin-top: 42px;
		}

		h2 {
			font-weight: 700;
			font-size: 24px;
			line-height: 28px;
			color: var(--primary);
			margin-top: 16px;
		}

		h5 {
			font-weight: 400;
			line-height: 24px;
			font-size: 18px;
			margin-top: 16px;
		}
	}

	& > .form-modal-inputs {
		max-width: 300px;
		margin-top: 42px;
		margin: auto;

		&::v-deep .field {
			.control > .icon.is-right {
				top: -5px!important;
			}
		}

		p {
			margin-top: 16px;
			font-size: 13px;
			font-weight: 600;
			line-height: 20px;
		}

		& > .btns {
			text-align: center;
			margin-top: 42px;
		}
	}
}

@keyframes opacity {
	from { 
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
</style>