<template>
	<div class="component-price">
		<div v-if="!gratuito">
			<div
				v-if="price !== null && specialPrice !== null"
				class="price"
			>
				<span>De: {{ priceReal }}</span>
			</div>
			<div
				v-if="specialPriceReal !== null"
				class="special_price"
			>
				<span>Por: </span>
				<span>{{ specialPriceReal }}</span>
			</div>
		</div>
		<div
			v-if="!gratuito && desconto !== null"
			class="desconto"
		>
			<span>{{ desconto }}%</span>
		</div>
		<div v-if="gratuito" class="gratuito">
			<span>Gratuito</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PriceComponentEBRADI',
	props: {
		price: {
			type: Number,
			required: true
		},
		specialPrice: {
			type: Number,
			required: false,
			default: null
		},
		desconto: {
			type: Number,
			required: false,
			default: null
		},
		gratuito: {
			type: Boolean,
			required: false,
			default: false,
		}
	},
	data() {
		return {

		}
	},
	computed: {
		priceReal() {
			return new Intl.NumberFormat('pt-BR', {
				style: 'currency',
				currency: 'BRL'
			}).format(this.price)
		},
		specialPriceReal() {
			if (this.specialPrice !== null) {
				return new Intl.NumberFormat('pt-BR', {
					style: 'currency',
					currency: 'BRL'
				}).format(this.specialPrice)
			} else if (this.price !== null) {
				return new Intl.NumberFormat('pt-BR', {
					style: 'currency',
					currency: 'BRL'
				}).format(this.price)
			}

			return null
		}
	}
}
</script>

<style lang="scss" scoped>

.component-price {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	width: max-content;

	.price {
		& > span {
			font-weight: 400;
			font-size: 18px;
			line-height: 24px;
			padding-left: 42px;
			text-decoration: line-through;
		}
	}

	.special_price {
		& > span:first-child {
			font-size: 20px;
			font-weight: 400;
			line-height: 24px;
			color: var(--secondary-400);
		}

		& > span:last-child {
			font-size: 28px;
			font-weight: 700;
			line-height: 24px;
			color: var(--secondary-400);
		}
	}

	.gratuito {
		font-size: 28px;
		font-weight: 700;
		line-height: 24px;
		color: var(--secondary-400);
	}

	.desconto {
		margin-left: 16px;
		background: var(--secondary-400);
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		width: 48px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--black);
		border-radius: 8px;
	}
}
</style>
